import { Button as ButtonLib, ButtonProps, makeStyles } from "@rneui/themed";

import Fonts from "../../constants/Fonts";

type VariantType = "default" | "destructive" | "cancel";
interface IProps extends ButtonProps {
  variant?: VariantType;
}
type ButtonType = "solid" | "clear" | "outline";

const getStylesFromVariant = (
  variant: VariantType,
  type: ButtonType,
  styles
) => {
  switch (variant) {
    case "destructive":
      return {
        buttonStyle: styles.buttonDestructive,
        titleStyle: styles.title
      };
    case "cancel":
      return {
        buttonStyle: styles.buttonCancel,
        titleStyle: styles.titleCancel
      };
    default:
      if (type === "clear") {
        return { buttonStyle: styles.button, titleStyle: styles.titleClear };
      } else return { buttonStyle: styles.button, titleStyle: styles.title };
  }
};

const getBorderWidthForOutline = (type: ButtonType) => {
  if (type === "outline") return { borderWidth: 1 };
  return {};
};

const Button = ({ variant = "default", ...props }: IProps) => {
  const styles = useStyles();

  const { buttonStyle, titleStyle } = getStylesFromVariant(
    variant,
    props.type,
    styles
  );
  const borderWidthStyle = getBorderWidthForOutline(props.type);

  return (
    <ButtonLib
      {...props}
      titleStyle={[titleStyle, props.titleStyle]}
      buttonStyle={[buttonStyle, borderWidthStyle, props.buttonStyle]}
    />
  );
};

const useStyles = makeStyles((theme) => {
  return {
    button: {
      borderRadius: 16
    },
    title: {
      fontFamily: Fonts.Inter[500]
    },
    titleClear: {
      fontFamily: Fonts.Inter[600],
      color: theme.colors.tealBlue
    },
    titleCancel: {
      fontFamily: Fonts.Inter[600],
      color: theme.colors.error
    },
    buttonCancel: {
      borderRadius: 16,
      backgroundColor: theme.colors.white
    },
    buttonDestructive: {
      borderRadius: 16,
      backgroundColor: theme.colors.error
    }
  };
});

export default Button;
