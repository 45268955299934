import { makeStyles } from "@rneui/themed";
import { View, ScaledSize, Image, Platform } from "react-native";
import ResponsiveBreakpoints from "../constants/ResponsiveBreakpoints";
import {
  downloadAppstore,
  downloadPlaystore,
  downloadAppstoreDark,
  downloadPlaystoreDark
} from "../assets/auth/onboarding";
import TouchableOpacity from "./ui/TouchableOpacity";
import { Linking_openURL } from "common/helpers/LinkingHelper";
import { useAppDispatch } from "common/redux";
import useGetCurrentColorScheme from "../hooks/useGetCurrentColorScheme";

const AppInstallButtons = () => {
  const styles = useStyles();
  const dispatch = useAppDispatch();

  const scheme = useGetCurrentColorScheme();

  if (Platform.OS !== "web") return null;
  return (
    <View style={styles.appDownloadContainer}>
      <TouchableOpacity
        onPress={() =>
          Linking_openURL(
            "https://apps.apple.com/us/app/copilotiq/id1665724212",
            dispatch
          )
        }
      >
        <Image
          style={styles.appDownloadImage}
          source={scheme === "dark" ? downloadAppstoreDark : downloadAppstore}
          resizeMode="contain"
        />
      </TouchableOpacity>
      <TouchableOpacity
        onPress={() =>
          Linking_openURL(
            "https://play.google.com/store/apps/details?id=com.copilotiq.patientapp",
            dispatch
          )
        }
      >
        <Image
          style={styles.appDownloadImage}
          source={scheme === "dark" ? downloadPlaystoreDark : downloadPlaystore}
          resizeMode="contain"
        />
      </TouchableOpacity>
    </View>
  );
};

const useStyles = makeStyles((theme, { width, height }: ScaledSize) => {
  const isSmallScreen =
    height < ResponsiveBreakpoints.PHONE_SMALL_DEVICE_HEIGHT_BREAKPOINT;
  return {
    appDownloadContainer: {
      flexDirection: "row",
      justifyContent: "space-evenly",
      marginBottom: isSmallScreen ? 0 : 15
    },
    appDownloadImage: {
      width: 150,
      height: 50
    }
  };
});

export default AppInstallButtons;
