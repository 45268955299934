import { DateTime } from "luxon";
import { isSafari, isMobileSafari } from "react-device-detect";

import { QueryString_stringify } from "common/helpers/QueryStringHelper";
import { AppDispatch } from "common/redux";
import { AnalyticsHelper_logSegmentEvent } from "common/helpers/firebase/AnalyticsHelper";

import { Linking_openURL } from "common/helpers/LinkingHelper";

const format = (date: DateTime) => {
  return date.toFormat("yyyyMMdd") + "T" + date.toFormat("HHmmss");
};

const URL = "https://calendar.google.com/calendar/render?";

interface IProps {
  title: string;
  description?: string;
  location?: string;
  startDate: DateTime;
  endDate?: DateTime;
  dispatch: AppDispatch;
  onFinish?: () => void;
}

const CalendarHelper_addEvent = ({
  title,
  description,
  location,
  startDate,
  endDate,
  dispatch,
  onFinish
}: IProps) => {
  AnalyticsHelper_logSegmentEvent("add_to_calendar_clicked");

  const startDateString = format(startDate);
  const endDateString = format(
    endDate ? endDate : startDate.plus({ hours: 1 })
  );

  const params = {
    action: "TEMPLATE",
    text: title,
    details: description,
    dates: `${startDateString}/${endDateString}`,
    location: location
  };
  const queryString = QueryString_stringify(params);
  if (isSafari || isMobileSafari) {
    window.location.href = URL + queryString;
  } else {
    Linking_openURL(URL + queryString, dispatch);
    onFinish && onFinish();
  }
};

export { CalendarHelper_addEvent };
