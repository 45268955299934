import { View } from "react-native";
import { makeStyles } from "@rneui/themed";

import { DateTime } from "luxon";

import Text from "./Text";
import Spacing from "./Spacing";
import useTextStyles from "./styles/useTextStyles";
import LocalizedStrings from "../../helpers/LocalizedStrings";
import { AccessibilityHelper_getAccessibilityProps } from "../../helpers/AccessibilityHelper";
const Row = ({ label, value }: { label: string; value: string }) => {
  const styles = useStyles();
  const textStyles = useTextStyles();
  return (
    <View style={[styles.row, styles.flexWrap]}>
      <Text
        body
        style={textStyles.colorDarkGreyBlue}
        {...AccessibilityHelper_getAccessibilityProps(label)}
      >
        {label}:
      </Text>
      <Spacing horizontal={2} />
      <Text
        capsLarge
        style={textStyles.colorDarkGreyBlue}
        {...AccessibilityHelper_getAccessibilityProps(value)}
      >
        {value}
      </Text>
    </View>
  );
};

interface IProps {
  startDate: DateTime;
  endDate: DateTime;
  staffName: string;
}

const ConfirmAppointmentBox = ({ startDate, endDate, staffName }: IProps) => {
  const styles = useStyles();

  return (
    <View style={styles.container}>
      <Row
        label={LocalizedStrings.screens.confirmAppointment.rowDate}
        value={startDate.toFormat("DDD")}
        {...AccessibilityHelper_getAccessibilityProps("Start Day")}
      />
      <Row
        label={LocalizedStrings.screens.confirmAppointment.rowTime}
        value={`${startDate.toFormat("hh:mm")} - ${endDate.toFormat("hh:mm a")}`}
        {...AccessibilityHelper_getAccessibilityProps("Start Time")}
      />
      <Row
        label={LocalizedStrings.screens.confirmAppointment.rowWith}
        value={staffName}
        {...AccessibilityHelper_getAccessibilityProps("Staff Name")}
      />
    </View>
  );
};

const useStyles = makeStyles((theme) => {
  return {
    container: {
      borderWidth: 1,
      borderColor: theme.colors.grey4,
      backgroundColor: theme.colors.searchBg,
      borderRadius: 5,
      marginVertical: 10,
      padding: 10,
      minWidth: 300
    },
    flexWrap: {
      flexWrap: "wrap"
    },
    row: {
      flexDirection: "row",
      alignItems: "center"
    }
  };
});

export default ConfirmAppointmentBox;
