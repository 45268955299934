import { useState } from "react";
import { View } from "react-native";

import Button from "../ui/Button";
import Input from "../ui/Input";
import Modal from "../ui/Modal";
import ReduxModalBody from "./ReduxModalBody";
import {
  SentryHelper_lastEventId,
  Sentry_captureFeedback
} from "common/helpers/SentryHelper";
import { getNameOrUsername } from "common/helpers/helpers";
import useGetAuthenticatedMember from "common/hooks/useGetAuthenticatedMember";
import Spacing from "../ui/Spacing";

const UserFeedbackModal = ({ visible, onRequestClose }) => {
  const [text, setText] = useState<string>("");

  const { data: patient } = useGetAuthenticatedMember();

  const handleSubmit = () => {
    let lastSentryEvent = SentryHelper_lastEventId();

    Sentry_captureFeedback({
      associated_event_id: lastSentryEvent,
      associatedEventId: lastSentryEvent,
      name: getNameOrUsername(patient?.patient),
      contact_email: patient?.patient?.email,
      email: patient?.patient?.email,
      message: text
    });

    handleClose();
  };

  const handleClose = () => {
    setText("");
    onRequestClose();
  };

  return (
    <Modal visible={visible} onRequestClose={onRequestClose}>
      <ReduxModalBody
        title="An error has occurred"
        content="Please submit an issue report and add any notes of what you experienced"
      >
        <View>
          <Input
            value={text}
            onChangeText={(text) => setText(text)}
            multiline
            placeholder="Write your feedback here"
          />

          <Spacing vertical={2} />

          <Button
            title={"Submit"}
            disabled={text.length < 4}
            onPress={handleSubmit}
          />
          <Spacing vertical={2} />
          <Button title={"Cancel"} type="outline" onPress={handleClose} />
        </View>
      </ReduxModalBody>
    </Modal>
  );
};

export default UserFeedbackModal;
